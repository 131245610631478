import React from 'react'
import { CenteredResponsiveContainer, Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'
import tw from 'twin.macro'

const Section = tw.div`mb-16`
const SectionHeading = tw.h2`text-2xl mb-4`

export default function Terms() {
  return (
    <Layout>
      <SEO title="Terms of use" />
      <section>
        <CenteredResponsiveContainer>
          <h1 className="text-3xl mb-5">Terms of use</h1>
          <Section>
            By accessing this website and any materials presented herein (the "Site"), you agree to
            the following terms and conditions pertaining to the use of the Site. Jump Trading, LLC
            and its affiliates or related companies (collectively, the “Company”) reserves the right
            to change the terms, conditions and notices under which this Site is offered without
            notice at any time. Each use of the Site constitutes your agreement to be bound by the
            then-current terms and conditions set forth in these Terms of Use. The Terms of Use
            incorporates the Privacy Policy.
          </Section>
          <Section>
            <SectionHeading>1. LIMITED LICENSE</SectionHeading>
            <div>
              Nothing contained in these Terms of Use or the Site itself grants or will be construed
              to grant to you or any third party any title or interest in or any license or right to
              use or reproduce, any image, text, software, code, trademark, logo or service mark
              contained in the Site, including without limitation the names or logos of the Company.
              The Company reserves, and will enforce to the fullest extent possible, all rights that
              it may have with respect to copyright and trademark ownership of all material
              contained in the Site. The Company grants you a limited, nonexclusive license to
              display and otherwise use portions of the Site solely for your own private,
              non-commercial informational purposes only, and to print pages from the Site only in
              connection with that use. You may not modify, distribute, transmit, perform,
              reproduce, publish, license, create derivative works from, transfer or sell any text,
              graphics, logos and other source-identifying symbols, designs, icons, images, or other
              information, software or code obtained from the Site.
            </div>
          </Section>

          <Section>
            <SectionHeading>2. NO WARRANTY</SectionHeading>
            <div>
              THE COMPANY AND ITS RESPECTIVE OFFICERS, MANAGERS, DIRECTORS, PRINCIPALS, AGENTS AND
              EMPLOYEES MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE
              ACCURACY, RELIABILITY, COMPLETENESS, SUITABILITY OR OTHER CHARACTERISTICS OF THE
              INFORMATION AND MATERIALS CONTAINED ON OR PRESENTED THROUGH THE SITE. ANY CONTENT OF
              THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. ALL SUCH INFORMATION AND MATERIALS ARE
              PROVIDED "AS IS", WITHOUT ANY WARRANTY OF ANY KIND. THE COMPANY HEREBY FURTHER
              DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO SUCH INFORMATION AND MATERIALS,
              INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT AND AVAILABILITY.
            </div>
          </Section>

          <Section>
            <SectionHeading>3. LIMITATION OF LIABILITY</SectionHeading>
            <div>
              IN NO EVENT SHALL THE COMPANY, AND ITS RESPECTIVE OFFICERS, MANAGERS, DIRECTORS,
              PRINCIPALS, AGENTS AND EMPLOYEES BE LIABLE FOR ANY CLAIMS, LIABILITIES, LOSSES, COSTS
              OR DAMAGES, INCLUDING DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
              DAMAGES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH (I) THE USE OF OR INABILITY TO
              USE THE SITE OR WITH ANY DELAY IN USING THE SITE, OR (II) ANY INFORMATION AND
              MATERIALS OBTAINED THROUGH THE SITE, OR (III) OTHERWISE ARISING OUT OF THE USE OF THE
              SITE; IN ANY CASE WHETHER BASED ON THEORIES ARISING IN CONTRACT, TORT, STRICT
              LIABILITY OR OTHERWISE. SUCH LIMITATIONS APPLY EVEN IF THE COMPANY OR ANY OF ITS
              RESPECTIVE OFFICERS, DIRECTORS, PRINCIPALS, AGENTS OR EMPLOYEES HAVE BEEN ADVISED OF
              THE POSSIBILITY OF DAMAGES.
            </div>
          </Section>

          <Section>
            <SectionHeading>4. GENERAL</SectionHeading>
            <div>
              These Terms of Use are governed by the internal laws of the State of Illinois, without
              reference to their conflicts of laws provisions. If any provision of these Terms of
              Use is held to be invalid or unenforceable in any jurisdiction, then such provision
              shall be deemed modified to the minimum extent necessary so that such provision shall
              no longer be held to be invalid or unenforceable and these Terms of Use shall be
              interpreted so as to achieve the intent expressed herein to the greatest extent
              possible in the jurisdiction in question. Any such modification, invalidity or
              unenforceability shall be strictly limited both to such provision and to such
              jurisdiction.
            </div>
          </Section>
        </CenteredResponsiveContainer>
      </section>
    </Layout>
  )
}
